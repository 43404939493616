import React, { useState, createContext, useEffect } from 'react'

export const ScrollbarContext = createContext()

export const ScrollbarProvider = ({ children, scrollbarInstance }) => {
  const [scrollbar, setScrollbar] = useState(null)

  useEffect(() => {
    setScrollbar(scrollbarInstance)
  }, [scrollbarInstance])

  return (
    <ScrollbarContext.Provider value={[scrollbar, setScrollbar]}>
      {children}
    </ScrollbarContext.Provider>
  )
}
